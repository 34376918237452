@font-face {
    font-family: Merriweather;
    src: url("../../fonts/Merriweather-LightItalic.otf") format("opentype");
    font-style: italic;
}
@font-face {
    font-family: Merriweather;
    src: url("../../fonts/Merriweather-Light.ttf") format('truetype');
}
@font-face {
    font-family: Qumpel;
    src: url("../../fonts/QumpellkaNo12.otf") format("opentype");
}
@import "./colours.scss";
body {
    overflow-x: hidden;
    font-family: "Merriweather",sans-serif;
    webkit-tap-highlight-color: #BC1559;
    padding: 0;
}
section.body {
    padding: 0;
}

.text-muted {
    color: #777;
}

.text-primary {
    color: #BC1559;
}

p {
    font-size: 14px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
    font-style: italic;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: #BC1559;
}

a:hover,
a:focus,
a:active,
a.active {
    color: #841F47;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.btn-primary {
    border-color: #BC1559;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: #BC1559;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    border-color: #7A1E43;
    color: #fff;
    background-color: #841F47;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: #BC1559;
    background-color: #BC1559;
}

.btn-primary .badge {
    color: #BC1559;
    background-color: #fff;
}

.btn-xl {
    padding: 20px 40px;
    border-color: #BC1559;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #BC1559;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    border-color: #7A1E43;
    color: #fff;
    background-color: #841F47;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    border-color: #BC1559;
    background-color: #BC1559;
}

.btn-xl .badge {
    color: #BC1559;
    background-color: #fff;
}

.navbar-default {
    border-color: transparent;
    @include gradient-bg;
}
.navbar-brand {
    padding: 0;
}

.navbar-default .navbar-brand {
    color: #BC1559;
}


.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: #841F47;
}

.navbar-default .navbar-collapse {
    border-color: rgba(255,255,255,.02);
}

.navbar-default .navbar-toggle {
    border-color: #BC1559;
    background-color: #BC1559;
    margin-top: 25px;
    .icon-bar {
      background-color: #fff;
  }
}


.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #BC1559;
}

.navbar-default .nav li a {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    color: #fff;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: #BC1559;
}
.navbar-default .nav li a:hover {
  cursor: pointer;
}

.navbar-default .navbar-nav>.active>a {
    border-radius: 0;
    color: #fff;
    background-color: #BC1559;
}

.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #841F47;
}

.navbar-brand{
    height: 100%;
    padding: 0px 15px;
}
.navbar-brand>img{
    height: 80px;
    padding-bottom: 0px;
}

nav.scrolled {
  padding: 0;
  background-color: #222;
  img {
      height: 80px;
  }
}

@media(min-width:768px) {
    .navbar-default {
        border: 0;
        background-color: transparent;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }
    
    .navbar-brand>img {
        height:120px;
    }

    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-default .navbar-nav>.active>a {
        border-radius: 3px;
    }

    .navbar-default.navbar-shrink {
        padding: 10px 0;
        background-color: #222;
    }

    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    
    // .navbar-nav{
    //     margin-top: 40px;
    // }

    .navbar-default.navbar-shrink .navbar-nav{
        margin-top: 12px;
    }
    .navbar-default.navbar-shrink .navbar-brand>img {
        height:60px;
    }
}

header {
    text-align: left;
    color: #fff;
    background-attachment: scroll;
    background-image: url('../../images/header-bg2.jpg');
    background-position: center center;
    background-repeat: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

header .intro-text {
    padding-top: 120px;
    padding-bottom: 50px;
}

header .intro-text .intro-lead-in {
    margin-bottom: 25px;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
}

header .intro-text .intro-heading {
    margin-bottom: 25px;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
}
.relaxText{
    display: inline;
    font-family: "Qumpel",sans-serif;
    font-weight: 500;
    font-size: 60px;
}
@media(min-width:768px) {
    header .intro-text {
        padding-top: 300px;
        padding-bottom: 200px;
    }

    header .intro-text .intro-lead-in {
        margin-bottom: 25px;
        font-size: 26px;
        font-style: italic;
        line-height: 40px;
    }

    header .intro-text .intro-heading {
        margin-bottom: 50px;
        font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 50px;
        font-weight: 700;
        line-height: 75px;
    }
}

section {
    padding: 100px 0;
}

section h2.section-heading {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 40px;
}

section h3.section-subheading {
    margin-bottom: 55px;
    text-transform: none;
    
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

@media(min-width:768px) {
    section {
        padding: 100px 0;
    }
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}

.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #f1f1f1;
}

.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: #BC1559;
}

.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}

.timeline .timeline-heading h4.subheading {
    text-transform: none;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0;
}

@media(min-width:768px) {
    .timeline:before {
        left: 50%;
    }

    .timeline>li {
        margin-bottom: 100px;
        min-height: 100px;
    }

    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
    }

    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
    }
}

@media(min-width:992px) {
    .timeline>li {
        min-height: 150px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px;
    }

    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 26px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
    }
}

@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px;
    }

    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 40px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
    }
}

.person {
    margin-bottom: 50px;
    text-align: center;
}

.person img {
    margin: 0 auto;
    border: 7px solid #fff;
}

.person h4 {
    margin-top: 25px;
    font-size:  1.4em;
    margin-bottom: 0;
    text-transform: none;
    font-family: "Qumpel",sans-serif;
}

.person p {
    margin-top: 0;
}

aside.clients img {
    margin: 50px auto;
}

section#testimonials h2.section-heading {
    margin-bottom: 35px;
}

section#booking {
  background-color: #222;
  background: url('../../images/bgcontact.jpg') 0 0 no-repeat fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;

  > #bookingOverlay {
    background: rgba(34,34,34,0.92);
    overflow: hidden;
    height: 100%;
    z-index: 2;
    padding: 150px 0;
  }
  .section-heading {
    color: #fff;
  }

  .form-group {
    margin-bottom: 25px;
  }


}

section#booking .form-group input,
section#booking .form-group textarea {
    padding: 18px;
    opacity: 0.8;
}

section#booking .form-group input.form-control {
    height: auto;
    
}

section#booking .form-group textarea.form-control {
    height: 236px;
}

section#booking .form-control:focus {
    border-color: #BC1559;
    box-shadow: none;
}

section#booking::-webkit-input-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#booking:-moz-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#booking::-moz-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#booking:-ms-input-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#booking .text-danger {
    color: #e74c3c;
}

footer {
    padding: 15px 0;
    text-align: center;
    background-color: #222;
    color: #4E4E4E;
}

footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 40px;
}

footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 40px;
}

ul.social-buttons {
    margin-bottom: 0;
}

ul.social-buttons li a {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
 img {
   width: 100%;
   height: 100%;
 }   
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0;
}
.fa{
    filter: invert(100%);
}

::-moz-selection {
    text-shadow: none;
    background: #BC1559;
}

::selection {
    text-shadow: none;
    background: #BC1559;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}
.pageSubheading{
    margin-bottom: 40px;
    margin-top:  60px;
}
.subsubheadingRow{
    text-align: center;
    overflow: hidden;
}
.equal{
    display: inline;
}

.subsubheadingRow .col-md-4, .subsubheadingRow .col-sm-6, #testimonials .subsubheadingRow > div {
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
  margin: 1%;
  margin-top: 10px;	
  box-shadow: 0px 0px 15px #BBB;
}


#testimonials .subsubheadingRow .testimonial {
  position: relative;
}

#testimonials .subsubheadingRow .testimonial > p{
    padding-left: 0px;
}

@media(min-width:480px) {
    .equal{
        display: flex;
	    flex-wrap: wrap;
    }
}
@media(max-width:614px) {
    .subsubheadingRow.additionalServices {
        text-align: left;
    }
    .subsubheadingRow.additionalServices > div {
        text-align: center;
    }
    .subsubheadingRow.additionalServices > div:nth-of-type(1){
        float: none;
        width: 100%;
    }
}
@media(min-width:480px) and (max-width:767px) {
    .subsubheadingRow.additionalServices > div {
        display: inline-block;
        padding: 17px 20px;
    }
    .subsubheadingRow.additionalServices > div:nth-of-type(1){
        float: left;
    }
    .subsubheadingRow.additionalServices > div:nth-of-type(2n){
        margin-right: auto;	
    }
}

@media(min-width:480px) and (max-width:991px) {
    .subsubheadingRow > div {
        width: 47%;
    }
    .subsubheadingRow > div:nth-of-type(2n-1){
        margin-left: 12px;	
    }
    .subsubheadingRow > div:nth-of-type(2n){
        margin-right: 0;	
    }
}

@media(max-width: 614px) {
  #testimonials .subsubheadingRow > div {
    width: initial;
  }
  #services .subsubheadingRow > div {
    width: 96%;

    img {
      max-width: 200px;
    }
  }
}

    
@media(min-width:992px) {
    .subsubheadingRow > div {
        width: 31%;
    }
    .subsubheadingRow > div:nth-of-type(3n-2){
        margin-left: 15px;	
    }
    .subsubheadingRow > div:nth-of-type(3n){
        margin-right: 0;	
    }
}


.subsubheadingRow .subsubimage{
    border-radius: 50%;
    width: 60%;
    margin: 20px 0px;
}
.subsubheadingRow .pageSubSubheading{
    margin: 30px 0px;
    font-family: "Qumpel",sans-serif;
}
.subsubheadingRow p{
    text-align: left;
    padding-left: 30px;
    
}
.subsubheadingRow ul{
    text-align: left;
}
.subsubcontent ul{
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.person img{
    border-radius: 40%;
    max-width: 300px;
}
.testimonial { 
    display: block;
    height: 100%;
    position:relative;
    padding-bottom: 15px;

    > p {
    padding-bottom: 15px;
    flex: 1;
    display: flex;
    }
    > div.name {
      font-style: italic;
      position: absolute;
      bottom: 5px;
      right: 5px;
      display: inline;
      width: auto;
  }
}
